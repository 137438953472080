import React, { useCallback, useEffect } from 'react'
import { Icon, theme } from '@damen/ui'
import Modal from 'react-modal'
import { styled } from 'styled-components'
import { useRouter } from 'next/router'
import { SURVEYMONKEYPOPUP_QUERYSTRING } from '@/components/Forms/constants'
import { StoryblokLink } from '@/queries/schema.generated'

// Modal does have this styling prop
const styles: Modal.Styles = {
  /* stylelint-disable selector-type-no-unknown */
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 900,
    width: '100vw',
    height: '100dvh',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    left: '0',
    padding: `${theme.spacing.x2}px`,
    border: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    opacity: '100%',
    pointerEvents: 'none',
  },
  /* stylelint-enable selector-type-no-unknown */
}

const Container = styled.div`
  position: relative;
  max-width: 600px;
  max-height: 650px;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.blueIce};
  pointer-events: all;
`
const CloseButton = styled.button`
  position: absolute;
  top: ${theme.spacing.x1}px;
  right: ${theme.spacing.x1}px;
  border: none;
  background-color: transparent;
`

interface Props {
  isOpen: boolean
  id: string
  title: string
  link: StoryblokLink
  handleCloseModal: () => void
}

const SurveyMonkeyPopupModal: React.FC<React.PropsWithChildren<Props>> = ({ id, isOpen, title, link, handleCloseModal }) => {
  const router = useRouter()
  const pageUrl = encodeURI(router.asPath.split(SURVEYMONKEYPOPUP_QUERYSTRING)[0])

  // The link from the CMS is the survey URL with custom variable
  // The value of the custom variable can be pasted directly after
  // Example: https://www.surveymonkey.com/r/123456?customVariable=
  const surveyUrl = `${link.url}${pageUrl}`

  const closeModal = useCallback(() => {
    handleCloseModal()
    if (router.asPath.includes(SURVEYMONKEYPOPUP_QUERYSTRING)) {
      router.back()
    }
  }, [handleCloseModal, router])

  useEffect(() => {
    if (!isOpen) {
      handleCloseModal()
    }
  }, [isOpen])

  return (
    <Modal
      ariaHideApp
      className="ReactModal__content"
      closeTimeoutMS={theme.timing.numDefault}
      contentLabel="Modal"
      isOpen={isOpen}
      overlayClassName="ReactModal__overlay"
      onRequestClose={closeModal}
      portalClassName="ReactModal"
      role="dialog"
      style={styles}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
    >
      <Container>
        <CloseButton type="button" onClick={closeModal} title="Close-SurveyMonkey">
          <Icon.Close width={18} height={18} fill={theme.colors.blue} />
        </CloseButton>
        <iframe
          id={`iframeContainer-${id}`}
          title={title}
          src={surveyUrl}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </Container>
    </Modal>
  )
}

export default SurveyMonkeyPopupModal
