import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import HighlightVideo from './HighlightVideo'
import reverseVideo from '@/utils/reverseVideo'

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface Props {
  video: string
  reverse?: boolean
  onFinishPlaying: () => void
}

const PortfolioHighlightsVideo = ({ video, reverse, onFinishPlaying }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  useEffect(() => {
    const elem = videoRef.current
    if (!elem) {
      return
    }

    const handleVideoEnded = () => {
      if (reverse) {
        return
      }
      onFinishPlaying()
    }

    elem.addEventListener('ended', handleVideoEnded)
    // eslint-disable-next-line consistent-return
    return () => {
      elem.removeEventListener('ended', handleVideoEnded)
    }
  }, [videoRef, onFinishPlaying, reverse])

  useEffect(() => {
    const elem = videoRef.current
    if (!reverse || !elem) {
      return
    }

    void reverseVideo(elem).then(onFinishPlaying)
  }, [reverse, videoRef, onFinishPlaying])

  return (
    <VideoContainer data-testid="highlightsBlok.activeHighlight">
      <HighlightVideo
        video={video}
        data-testid="highlightsBlok.activeHighlight.video"
        ref={videoRef}
      />
    </VideoContainer>
  )
}

export default PortfolioHighlightsVideo
