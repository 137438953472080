import React, { useEffect, useRef } from 'react'
import { NextImage, theme } from '@damen/ui'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import styled from 'styled-components'

import { setAnimations } from '@/components/UI/HomeHeader/animations'
import { HomeHeaderProps } from './types'

interface Content {
  id: string
  header: HomeHeaderProps
}

interface Props {
  content: Content
  delay: number
}

const HomeHeader: React.FC<React.PropsWithChildren<Props>> = ({ content, delay }) => {
  const { header } = content
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    setAnimations({ containerRef, delay, lockScroll: true })

    return () => {
      ScrollTrigger.killAll()
    }
  }, [delay])

  return (
    <Container ref={containerRef}>
      <ContentWrapper>
        <Relative>
          <Intro className="intro">
            <h1 className="intro-title">{header.title}</h1>
            <h2 className="intro-subtitle">{header.subtitle} </h2>
          </Intro>
          <Message className="message">
            <h2>{header.message}</h2>
          </Message>
          <ImageWrapper>
            <Image priority className="background" alt={header.img.alt} src={header.img.filename} fill />
            <Overlay className="overlay" />
          </ImageWrapper>
        </Relative>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  min-height: 220vh;
`

const ContentWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`

const Relative = styled.div`
  position: relative;
`

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.body.style.fontFamily};
  text-align: center;
  z-index: 1;

  h1 {
    width: 100%;
    max-width: 688px;
    margin: 0 80px 32px;
    font-size: 100px;
    line-height: 1.15;
    transform: scale(0.8);

    @media ${theme.legacyMediaQueries.mdMax} {
      max-width: 325px;
      margin: 0 16px 16px;
      font-size: 42px;
    }
  }

  h2 {
    width: 100%;
    max-width: 688px;
    margin: 0 80px;
    font-size: 30px;
    line-height: 1.4;
    opacity: 0;
    transform: translateY(-50px);

    @media ${theme.legacyMediaQueries.mdMax} {
      max-width: 325px;
      margin: 0 16px;
      font-size: 22px;
    }
  }
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.body.style.fontFamily};
  text-align: center;
  opacity: 0;
  transform: translateY(calc(100vh / 10));

  h2 {
    width: 100%;
    max-width: 784px;
    margin: 0 80px;
    font-size: 60px;
    transform: scale(0.8);
    line-height: 1.17;

    @media ${theme.legacyMediaQueries.mdMax} {
      max-width: 100%;
      margin: 0 16px;
      font-size: 40px;
    }
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
`

const Image = styled(NextImage)`
  width: 100%;
  height: 105vh !important;
  transform: scale(1.25);
  object-fit: cover;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: ${theme.colors.marineBlack};
`

export default HomeHeader
