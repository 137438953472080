import React from 'react'
import { theme } from '@damen/ui'
import { LinkWrapper, LogoWrapper } from '../styles'
import { Logo } from '@/components/Logo'

interface Props {
  isMenuOpen?: boolean
  variant: 'light' | 'dark'
}

const SiteNavigationLogo: React.FC<React.PropsWithChildren<Props>> = ({ isMenuOpen, variant, ...props }) => (
  <LogoWrapper isOpen={isMenuOpen} {...props}>
    <LinkWrapper type="link" link="/">
      <Logo fill={variant === 'dark' || isMenuOpen ? theme.colors.blue : theme.colors.white} width={200} />
    </LinkWrapper>
  </LogoWrapper>
)

export default SiteNavigationLogo
