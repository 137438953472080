import styled from 'styled-components'
import React, { forwardRef, memo } from 'react'

import makeCloudinaryVideoUrl from '@/utils/makeCloudinaryVideoUrl'
import CloudinaryVideo from './CloudinaryVideo'
import BynderVideo from './BynderVideo'
import isBynderUrl from '@/utils/isBynderUrl'
import getPublicIdFromCloudinaryUrl from '@/utils/getPublicIdFromCloudinaryUrl'

const StyledVideo = styled(CloudinaryVideo)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const StyledBynderVideo = styled(BynderVideo)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const transformations = [
  // By default a keyframe is inserted in videos about once every 2
  // seconds. When reversing, the video doesn't actually animate until
  // it hits a keyframe. To have the video animate within an
  // imperceptible time, we need to have a keyframe somewhat close to
  // the end of the video.
  // A value above 0.2 may cause noticable jumping of the video.
  `ki_0.2`, // in seconds
]

// When preloading we assume mp4 since it's supported all major browsers
export const getPreloadUrl = (video: string) => {
  if (isBynderUrl(video)) {
    return video
  }
  
  const publicId = getPublicIdFromCloudinaryUrl(video)
  return makeCloudinaryVideoUrl({
    publicId,
    format: 'mp4',
    transformations,
  })
}

// Cloudinary seems to have trouble with applying the transformations to webm
const formatBlacklist = ['webm']

interface Props {
  video: string
}

/**
 * @param video - Bynder only supports mp4 format
 */

// eslint-disable-next-line react/display-name
const HighlightVideo = forwardRef<HTMLVideoElement, Props>(({ video, ...others }, ref) => {
  if (isBynderUrl(video)) {
    return <StyledBynderVideo video={video} autoPlay
    // Needed for autoPlay to work in modern browsers
    muted
    // Needed for Safari to auto play
    playsInline
    // Hide Edge intrusive PIP button
    disablePictureInPicture ref={ref} {...others} />
  }

  const publicId = getPublicIdFromCloudinaryUrl(video)
  return (
    <StyledVideo
      publicId={publicId}
      autoPlay
      // Needed for autoPlay to work in modern browsers
      muted
      // Needed for Safari to auto play
      playsInline
      // Hide Edge intrusive PIP button
      disablePictureInPicture
      transformations={transformations}
      formatBlacklist={formatBlacklist}
      ref={ref}
      {...others}
    />
  )
})

// video restarts the video each time it's rerendered, so we need to memoize
// it to avoid it from rerendering unnnecessarily.
export default memo(HighlightVideo)
