import React, { ComponentProps, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { makeVar, useReactiveVar } from '@apollo/client'
import { theme, Icon } from '@damen/ui'

import preloadVideo from '@/utils/preloadVideo'
import { getPreloadUrl } from './HighlightVideo'

import IntegratedServicesHighlightsImage from './IntegratedServicesHighlightsImage'
import IntegratedServicesHighlightsVideo from './IntegratedServicesHighlightsVideo'
import mergeRefs from '@/utils/mergeRefs'
import IntegratedServicesPreloading from './IntegratedServicesPreloading'
import { HotSpotSecondLevel } from '../types'
import {
  HighlightCloseButton,
  HighlightContent,
  HighlightContentMobile,
  Positioner,
  SubTitle,
  Title,
  activeHighlightCss,
} from '../styles'

/**
 * This var represents whether the highlight video is "presenting" the feature
 * shown by its final frame.
 */
export const highlightVideoPresentingVar = makeVar(false)

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'hasActiveHighlight',
})<{ hasActiveHighlight?: boolean }>`
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  background: transparent;

  ${({ hasActiveHighlight }) =>
    hasActiveHighlight &&
    css`
      ${activeHighlightCss}
    `}

  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    top: initial;
    min-height: auto;
    padding: ${theme.spacing.x10}px 0;
  }
`

const VisualContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'hideImageOverlay',
})<{ hideImageOverlay?: boolean }>`
  position: relative;
  padding-top: 56.25%; // 16:9
`

const StyledHighlightCloseButton = styled(HighlightCloseButton)`
  z-index: 2;
`

interface Props extends Omit<ComponentProps<typeof Container>, 'children' | 'title' | 'sectionIndicatorVariant'> {
  title: ReactNode
  subTitle: ReactNode
  buttons: HotSpotSecondLevel[]
  setNestedHighlightActive: React.Dispatch<React.SetStateAction<boolean>>
  mobileMargins?: number
}

const IntegratedServicesHighlightSecond = ({
  title,
  subTitle,
  buttons,
  setNestedHighlightActive,
  mobileMargins = 0,
  ...others
}: Props) => {
  const [ref, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const highlightVisualRef = useRef<HTMLDivElement>(null)

  const [loadingHighlightProgress, setLoadingHighlightProgress] = useState<[HotSpotSecondLevel, number] | undefined>()

  const [activeHighlight, setActiveHighlight] = useState<HotSpotSecondLevel | undefined>()

  const openHighlight = async (locationBlock: HotSpotSecondLevel) => {
    if (loadingHighlightProgress) {
      // Already loading a highlight
      return
    }

    try {
      await preloadVideo(getPreloadUrl(locationBlock.video), (progress) => {
        setLoadingHighlightProgress([locationBlock, progress])
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to preload highlights video due to error:', err)
    } finally {
      setLoadingHighlightProgress(undefined)
    }

    setActiveHighlight(locationBlock)
  }

  const [isClosing, setIsClosing] = useState(false)
  const handleFinishPlaying = useCallback(() => {
    if (isClosing) {
      setIsClosing(false)
      setActiveHighlight(undefined)
      return
    }

    highlightVideoPresentingVar(true)
  }, [isClosing])
  const handleClose = useCallback(() => {
    setIsClosing(true)
    highlightVideoPresentingVar(false)
  }, [])
  const isPresenting = useReactiveVar(highlightVideoPresentingVar)

  useEffect(() => {
    setNestedHighlightActive(!!activeHighlight)

    return () => {
      setNestedHighlightActive(false)
    }
  }, [activeHighlight, setNestedHighlightActive])

  return (
    <Container
      data-testid="highlightsBlokSecondLevel"
      ref={mergeRefs(ref, containerRef)}
      style={{
        ['--mobile-margins' as any]: `calc(${mobileMargins / 100} * 100vw)`,
      }}
      {...others}
    >
      <Positioner ref={highlightVisualRef}>
        <VisualContainer>
          <IntegratedServicesHighlightsImage<HotSpotSecondLevel>
            image={null}
            buttons={buttons}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onButtonClick={openHighlight}
            showButtons={!activeHighlight && inView}
            getLoadingProgress={(button) => {
              if (!loadingHighlightProgress || button !== loadingHighlightProgress[0]) {
                return undefined
              }

              return loadingHighlightProgress[1]
            }}
          />

          {activeHighlight && (
            <IntegratedServicesHighlightsVideo
              video={activeHighlight.video}
              reverse={isClosing}
              onFinishPlaying={handleFinishPlaying}
            />
          )}
        </VisualContainer>

        {!activeHighlight && !isPresenting && (
          <>
            <HighlightContent>
              <Title>{title}</Title>
              <SubTitle>{subTitle}</SubTitle>
            </HighlightContent>

            <HighlightContentMobile>
              <Title>{title}</Title>
            </HighlightContentMobile>
          </>
        )}

        {activeHighlight && isPresenting && (
          <>
            <StyledHighlightCloseButton onClick={handleClose} data-testid="highlightsBlokSecond.close">
              <Icon.RightChevron width={14} height={14} />
            </StyledHighlightCloseButton>

            {/* TODO Add slider */}
            <>
              <HighlightContent>
                <Title>{title}</Title>
                <SubTitle>{subTitle}</SubTitle>
              </HighlightContent>

              <HighlightContentMobile>
                <Title>{title}</Title>
              </HighlightContentMobile>
            </>
          </>
        )}
      </Positioner>

      <IntegratedServicesPreloading buttons={buttons} />
    </Container>
  )
}

export default IntegratedServicesHighlightSecond
