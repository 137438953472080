import React from 'react'
import { useReactiveVar } from '@apollo/client'
import SurveyMonkeyButton from './Button'
import { showSurveyMonkeyModal } from '@/lib/apollo/useApollo'
import Modal from '../Modal'
import { SurveyMonkey as SurveyMonkeyProps } from '@/queries/schema.generated'
import setBodyScrollLock from '@/utils/bodyScrollLock'

interface Props {
  surveyMonkey?: SurveyMonkeyProps
}

const SurveyMonkey: React.FC<React.PropsWithChildren<Props>> = ({ surveyMonkey }) => {
  const showSurveyMonkey = useReactiveVar(showSurveyMonkeyModal)
  const enabled = surveyMonkey?.enabled
  const buttonText = surveyMonkey?.buttonText
  const link = surveyMonkey?.link

  return (
    enabled && (
      <>
        {buttonText && <SurveyMonkeyButton text={buttonText} />}
        <Modal.SurveyMonkeyModal
          isOpen={showSurveyMonkey}
          link={link}
          handleCloseModal={() => {
            showSurveyMonkeyModal(false)
            setBodyScrollLock(false)
          }}
        />
      </>
    )
  )
}

export default SurveyMonkey
