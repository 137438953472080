import React from 'react'
import Head from 'next/head'

import { getPreloadUrl } from './HighlightVideo'

interface Props {
  buttons: { video?: string }[]
}

const IntegratedServicesPreloading = ({ buttons }: Props) => {
  return (
    <>
      {buttons.map(
        (button) =>
          button.video && (
            <Head key={button.video}>
              <link rel="prefetch" href={getPreloadUrl(button.video)} />
            </Head>
          ),
      )}
    </>
  )
}

export default IntegratedServicesPreloading
