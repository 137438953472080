import Contact from './Contact'
import DepartmentContact from './DepartmentContact'
import DepartmentNewsletter from './DepartmentNewsletter'
import Donation from './Donation'
import Download from './Download'
import Newsletter from './Newsletter'
import Feedback from './Feedback'
import SurveyMonkeyModal from './SurveyMonkey'
import SurveyMonkeyPopupModal from './SurveyMonkeyPopup'

export default {
  Contact,
  DepartmentContact,
  DepartmentNewsletter,
  Donation,
  Download,
  Newsletter,
  Feedback,
  SurveyMonkeyModal,
  SurveyMonkeyPopupModal,
}
