/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useLayoutEffect, useRef, useState } from 'react'
import { LinearBlur, theme } from '@damen/ui'
import styled, { css } from 'styled-components'

import Link from '@/components/Link'
import { NAVIGATION_BREAKPOINT_LARGE } from './consts'
import {
  GroupLabel,
  MenuFooter,
  MenuFooterContent,
  MenuGroup,
  MenuItem,
  MenuList,
  SubMenuItem,
  UnderlineStyle,
} from './styles'
import NeedHelp from './components/NeedHelp'
import MenuLinkItem from './components/MenuLinkItem'
import {
  SiteNavigationItem,
  SiteNavigationNeedHelp,
  SiteNavigationProduct,
  SiteNavigationSecondSubItem,
  SiteNavigationSecondSubItemsGroup,
  SiteNavigationSubItem,
  SiteNavigationSubItemsGroup,
  StoryblokLink,
} from '@/queries/schema.generated'
import MainSublevelLink from './components/MainSublevelLink'
import ProductSublevelLink from './components/ProductSublevelLink'
import SupportingItem from './components/SupportingItem'
import { hexToRgb } from '@/helpers/common'
import { scrollbarBlue } from '@/styles/scrollbars'

const ExpandedMenuContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  // Add the SitenavigationBar border height to the top position
  top: calc(100% + 2px);
  min-height: 0;
  height: 100%;
  max-height: 0;
  margin: 0;
  overflow: hidden;
  visibility: hidden;
  background: linear-gradient(
    90deg,
    ${hexToRgb(theme.colors.white, { format: 'css' })} 0%,
    ${hexToRgb(theme.colors.white, { format: 'css' })} 35%,
    ${hexToRgb(theme.colors.blueIce, { format: 'css' })} 35%,
    ${hexToRgb(theme.colors.blueIce, { format: 'css' })} 100%
  );
  opacity: 0;
  // Make use of the MenuOverlay to close the menu
  pointer-events: none;

  ${({ isOpen }) =>
    isOpen &&
    `
    // Min height from design is 310px
    min-height: clamp(min(310px), var(--highest-column-height), calc(80dvh - 84px));
    max-height: clamp(min(310px), var(--highest-column-height), calc(80dvh - 84px));
    visibility: visible;
    opacity: 1;
    color: ${theme.colors.marineBlack};
    transition: max-height 0.8s cubic-bezier(1,0,1,0), min-height 0.5s ease-in-out;
  `}
`

const ExpandedMenuWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  // Menu footer should always be visible so subtract the height from the footer
  height: calc(100% - 42px);
  margin: 0;
  opacity: 0;
  // Prevent closing the menu when clicking inside the content area
  pointer-events: all;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
  `}

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    margin: 0 auto;
    max-width: 1040px;
  }

  @media (${theme.mediaQueries.mediaQueryDesktopLarge}) {
    max-width: ${theme.layout.gridWidthInner};
  }
`

const ColumnWrapper = styled.div`
  padding: ${theme.spacing.x4}px;
`

const FirstColumnMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 33.33%;
  border-right: 1px solid ${theme.colors.blueIce};
  height: 100%;
  background-color: ${theme.colors.white};

  ${ColumnWrapper} {
    padding-left: 0;
  }
`

const SecondColumnMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: absolute;
  z-index: 1;
  // The borders from the first column are not included in the width and height calculation of the second column
  // So we need to add that here
  top: 0;
  left: calc(24.33% - 2px);
  width: calc((100% - 24.33%) + 1px);
  height: 100%;
  opacity: 0;
  border-right: 1px solid ${theme.colors.blueIce};
  border-left: 1px solid ${theme.colors.blueIce};
  background-color: ${theme.colors.white};
  visibility: hidden;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  transition-delay: 0s, 0s, 0.1s;

  ${ColumnWrapper} {
    padding-left: ${theme.spacing.x4}px;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
    // include spacing in the translate calculation
    transform: translateX(calc(100% + 2px));
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition-delay: 0.1s, 0.1s, 0s;
  `}
`

const ThirdColumnMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: absolute;
  z-index: 1;
  // The borders from the first column are not included in the width and height calculation of the second column
  // So we need to add that here
  top: 0;
  left: calc(-66.66% - 2px);
  // 66% = 24 (width of 2nd column) + 42 (width of 3rd column)
  width: calc((100% + 66.66%) - 1px);
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  transition-delay: 0s, 0s, 0.1s;

  ${ColumnWrapper} {
    padding-left: ${theme.spacing.x4}px;
    padding-right: ${theme.spacing.x05}px;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
    // include spacing in the translate calculation
    transform: translateX(calc(100% + 2px));
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition-delay: 0.1s, 0.1s, 0s;
  `}
`

const MainMenuItems = styled(MenuList)`
  display: none;

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

const SupportingItemsContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['compact', 'isOpen'].includes(prop),
})<{
  compact: boolean
  isOpen: boolean
}>`
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: ${theme.spacing.x4}px;
  width: 66.66%;
  height: 100%;
  margin-left: 33.33%;
  opacity: 1;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in-out;

  ${({ compact }) =>
    compact &&
    css`
      // 41.25% =>
      // first column = 33% + 1px border
      // second column = 8.25% of 33 + 1px border
      width: calc(41.25% + 2px);
      transform: translateX(calc(61.66% - 3px));
    `}

  ${({ isOpen }) =>
    !isOpen &&
    css`
      opacity: 0;
    `}
`

const SupportingItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4}px;
  height: 100%;
  padding: ${theme.spacing.x4}px 0 ${theme.spacing.x4}px ${theme.spacing.x4}px;
`

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  ${scrollbarBlue}
`

const StyledLink = styled(Link.VNext).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
  isActive: boolean
}>`
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};

  &:focus {
    color: ${theme.colors.blue};
    outline: ${theme.accessibility.outline};
    outline-offset: 8px;
  }

  gap: ${theme.spacing.x05}px;

  svg {
    max-width: 10px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${UnderlineStyle}
      color: ${theme.colors.blue} !important;
      font-weight: ${theme.typography.fontWeightMedium};
    `}
`

interface Props {
  isMenuOpen?: boolean
  items?: SiteNavigationItem[]
  needHelp?: SiteNavigationNeedHelp
  variant?: 'light' | 'dark'
  isAtTop: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  activeItem: string
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  currentPage: string
}

export interface SecondColumnProps {
  activeItem: string
  currentPage: string
  subLink: StoryblokLink
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  linkText?: string
  subItems: SiteNavigationSecondSubItemsGroup[]
  columnRef: React.RefObject<HTMLDivElement | null>
  thirdColumnRef: React.RefObject<HTMLDivElement | null>
}

export interface ThirdColumnProps {
  activeItem: string
  currentPage: string
  subLink: StoryblokLink
  linkText?: string
  subItems: SiteNavigationProduct[]
  columnRef: React.RefObject<HTMLDivElement | null>
}

export interface MainMenuItemProps {
  id: string
  activeItem: string
  link: StoryblokLink
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  title: string
  subItems: SiteNavigationSubItemsGroup[]
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  isMenuOpen: boolean
  currentPage: string
}

const toggleMenuOverlayAndFirstColumn = (
  subItems: SiteNavigationSubItemsGroup[],
  link: StoryblokLink,
  activeItem: string,
  setIsMenuOpen: (menuOpen?: boolean) => void,
  setActiveItem: React.Dispatch<React.SetStateAction<string>>,
  isMenuOpen: boolean,
  currentPage: string,
): (() => void) => {
  return subItems.length > 0
    ? () => {
        if ((link.url === activeItem || activeItem.startsWith(link.url)) && isMenuOpen) {
          setIsMenuOpen(false)
        } else if ((link.url === activeItem || activeItem.startsWith(link.url)) && !isMenuOpen) {
          setIsMenuOpen(true)
        } else if (currentPage.startsWith(link.url)) {
          setActiveItem(currentPage)
        } else {
          setIsMenuOpen(true)
          setActiveItem(link.url)
        }
      }
    : undefined
}

/**
 * Toggles the active column in the site navigation based on the active item.
 * @param currentPage - The current page, only used within SiteNavigationDesktop.
 */
export const toggleColumn = (
  subItems: SiteNavigationProduct[] | SiteNavigationSecondSubItemsGroup[],
  subLink: StoryblokLink,
  activeItem: string,
  setActiveItem: React.Dispatch<React.SetStateAction<string>>,
  link: StoryblokLink,
  currentPage: string | null = null,
): (() => void) => {
  return subItems.length > 0
    ? () => {
        if (subLink.url === activeItem) {
          setActiveItem(link.url)
        } else if (currentPage?.startsWith(subLink.url)) {
          setActiveItem(currentPage)
        } else {
          setActiveItem(subLink.url)
        }
      }
    : undefined
}

const ThirdColumn: React.FC<React.PropsWithChildren<ThirdColumnProps>> = ({
  activeItem,
  subLink,
  linkText,
  subItems,
  currentPage,
  columnRef,
}) => {
  const isThirdColumnActive = subLink.url === activeItem || activeItem.startsWith(subLink.url)

  return (
    <ThirdColumnMenu isOpen={isThirdColumnActive}>
      <ScrollContainer>
        <ColumnWrapper ref={isThirdColumnActive ? columnRef : null}>
          {linkText && (
            <MainSublevelLink
              link={subLink}
              size="small"
              title={linkText}
              currentPage={currentPage}
              data-testid="navigationBar.item.main.sublevel.link"
            />
          )}
          <MenuList>
            {subItems.map(({ id, title, link, image, description, iconType }) => (
              <li key={id}>
                <ProductSublevelLink
                  key={id}
                  id={id}
                  title={title}
                  link={link}
                  image={image}
                  text={description}
                  iconType={iconType}
                  currentPage={currentPage}
                />
              </li>
            ))}
          </MenuList>
        </ColumnWrapper>
      </ScrollContainer>
      <LinearBlur hexColor={theme.colors.blueIce} />
    </ThirdColumnMenu>
  )
}

const SecondColumn: React.FC<React.PropsWithChildren<SecondColumnProps>> = ({
  activeItem,
  subLink,
  setActiveItem,
  linkText,
  subItems,
  currentPage,
  columnRef,
  thirdColumnRef,
}) => {
  const isSecondColumnActive = subLink.url === activeItem || activeItem.startsWith(subLink.url)

  return (
    <SecondColumnMenu isOpen={isSecondColumnActive}>
      <ScrollContainer>
        <ColumnWrapper ref={isSecondColumnActive ? columnRef : null}>
          {linkText && (
            <MainSublevelLink
              link={subLink}
              size="small"
              title={linkText}
              currentPage={currentPage}
              data-testid="navigationBar.item.main.sublevel.link"
            />
          )}
          {subItems?.map(({ label: secondLevelLabel, subItems: secondLevelGroupSubItems, id: secondLevelId }) => (
            <MenuGroup key={secondLevelId}>
              {secondLevelLabel && <GroupLabel>{secondLevelLabel}</GroupLabel>}
              <MenuList>
                {secondLevelGroupSubItems?.map(
                  ({
                    id: secondLevelSubId,
                    title: secondLevelSubTitle,
                    link: secondLevelSubLink,
                    linkText: secondLevelSubLinkText,
                    products: secondLevelSubItems,
                  }) => (
                    <SubMenuItem key={secondLevelSubId}>
                      <MenuLinkItem
                        title={secondLevelSubTitle}
                        link={secondLevelSubLink}
                        currentPage={currentPage}
                        isActive={
                          secondLevelSubLink?.url === activeItem || activeItem.startsWith(secondLevelSubLink.url)
                        }
                        onClick={toggleColumn(
                          secondLevelSubItems,
                          secondLevelSubLink,
                          activeItem,
                          setActiveItem,
                          subLink,
                          currentPage,
                        )}
                      />
                      {/* THIRD COLUMN */}
                      {isSecondColumnActive && (
                        <ThirdColumn
                          activeItem={activeItem}
                          currentPage={currentPage}
                          subLink={secondLevelSubLink}
                          linkText={secondLevelSubLinkText}
                          subItems={secondLevelSubItems}
                          columnRef={thirdColumnRef}
                        />
                      )}
                    </SubMenuItem>
                  ),
                )}
              </MenuList>
            </MenuGroup>
          ))}
        </ColumnWrapper>
      </ScrollContainer>
      <LinearBlur />
    </SecondColumnMenu>
  )
}

const MainMenuItem: React.FC<React.PropsWithChildren<MainMenuItemProps>> = ({
  activeItem,
  isMenuOpen,
  setActiveItem,
  setIsMenuOpen,
  link,
  title,
  subItems,
  currentPage,
  ...props
}) => {
  if (link.url === currentPage && !subItems) {
    return title
  }

  return (
    <StyledLink
      link={link}
      isActive={(link.url === activeItem || activeItem.startsWith(link.url)) && isMenuOpen}
      type="link"
      onClick={toggleMenuOverlayAndFirstColumn(
        subItems,
        link,
        activeItem,
        setIsMenuOpen,
        setActiveItem,
        isMenuOpen,
        currentPage,
      )}
      text={title}
      prefetch={false}
      {...props}
    >
      {title}
    </StyledLink>
  )
}

const getFirstColumnActiveObject = (
  activeItem: string,
  items: SiteNavigationItem[],
): SiteNavigationSubItem | undefined => {
  return items
    .flatMap((item) => item?.subItems || [])
    .flatMap((subItemGroup) => subItemGroup?.subItems || [])
    .flatMap((subItem) => {
      if (subItem?.link?.url === activeItem) {
        return [subItem]
      }
      return (
        subItem?.subItems?.flatMap((secondColumnSubItemGroup) =>
          secondColumnSubItemGroup?.subItems
            ?.filter((secondColumnSubItem) => secondColumnSubItem?.link?.url === activeItem)
            .map(() => subItem),
        ) || []
      )
    })
    .find((subItem) => subItem !== undefined)
}

const getSecondColumnActiveObject = (
  activeItem: string,
  items: SiteNavigationItem[],
): SiteNavigationSecondSubItem | undefined => {
  return items
    .flatMap((item) => item?.subItems || [])
    .flatMap((subItemGroup) => subItemGroup?.subItems || [])
    .flatMap((subItem) => subItem?.subItems || [])
    .flatMap((secondColumnSubItemGroup) => secondColumnSubItemGroup?.subItems || [])
    .find((secondColumnSubItem) => secondColumnSubItem?.link?.url === activeItem)
}

const SiteNavigationDesktop: React.FC<React.PropsWithChildren<Props>> = ({
  activeItem,
  isMenuOpen,
  items,
  needHelp,
  setActiveItem,
  setIsMenuOpen,
  variant = 'light',
  isAtTop,
  currentPage,
}) => {
  const firstColumnRef = useRef<HTMLDivElement>(null)
  const secondColumnRef = useRef<HTMLDivElement>(null)
  const thirdColumnRef = useRef<HTMLDivElement>(null)
  const secondColumnActiveItemObject = getFirstColumnActiveObject(activeItem, items)
  const thirdColumnActiveObject = getSecondColumnActiveObject(activeItem, items)
  // We need a default height so the initial menu animation will work
  // 735 -> This is the height of the current vessels menu
  const [menuHeight, setMenuHeight] = useState<number>(735)

  useLayoutEffect(() => {
    const activeFirstColumnHeight = firstColumnRef?.current?.offsetHeight
    const activeSecondColumnHeight = secondColumnRef?.current?.offsetHeight
    const activeThirdColumnHeight = thirdColumnRef?.current?.offsetHeight

    if (activeFirstColumnHeight && !activeSecondColumnHeight && !activeThirdColumnHeight) {
      setMenuHeight(Math.max(activeFirstColumnHeight))
    } else if (activeFirstColumnHeight && activeSecondColumnHeight && !activeThirdColumnHeight) {
      setMenuHeight(Math.max(activeFirstColumnHeight, activeSecondColumnHeight))
    } else if (activeFirstColumnHeight && activeSecondColumnHeight && activeThirdColumnHeight) {
      setMenuHeight(Math.max(activeFirstColumnHeight, activeSecondColumnHeight, activeThirdColumnHeight))
    }
  }, [activeItem])

  return (
    <MainMenuItems data-testid="site-navigation-header-items">
      {items.map(({ id, title, link, linkText, subItems, supportingItem }) => {
        const Component = MenuItem
        const isFirstColumnOpen = link.url === activeItem || activeItem.startsWith(link.url)
        // The idea is that the compact version should be shown when the second column is shown
        const condenseSupportingItem =
          activeItem.split('/').length >= 3 && secondColumnActiveItemObject?.subItems?.length > 0
        // When the third column is shown this item should not be open in all other cases it should be displayed
        const hideSupportingItem =
          activeItem.split('/').length < 4 ||
          (activeItem.split('/').length === 4 && thirdColumnActiveObject?.products?.length === 0)

        return (
          <Component key={id} isMenuOpen={isMenuOpen} variant={variant} isAtTop={isAtTop}>
            <MainMenuItem
              id={id}
              title={title}
              activeItem={activeItem}
              link={link}
              setActiveItem={setActiveItem}
              setIsMenuOpen={setIsMenuOpen}
              subItems={subItems}
              isMenuOpen={isMenuOpen}
              currentPage={currentPage}
            />
            {subItems?.length > 0 && (
              // Menu footer height should be included in the total height of the expanded menu
              <ExpandedMenuContainer
                isOpen={isMenuOpen}
                style={{ ['--highest-column-height' as any]: `calc(${menuHeight}px + 43px)` }}
              >
                <ExpandedMenuWrapper isOpen={isFirstColumnOpen}>
                  {/* FIRST COLUMN */}
                  <FirstColumnMenu isOpen>
                    <ScrollContainer>
                      <ColumnWrapper ref={isFirstColumnOpen ? firstColumnRef : null}>
                        {linkText && (
                          <MainSublevelLink link={link} size="large" title={linkText} currentPage={currentPage} />
                        )}
                        {subItems?.map(({ label, subItems: groupSubItems, id: subItemId }) => (
                          <MenuGroup key={subItemId}>
                            {label && <GroupLabel>{label}</GroupLabel>}
                            <MenuList>
                              {groupSubItems?.map(
                                ({
                                  id: subId,
                                  title: subTitle,
                                  link: subLink,
                                  linkText: subLinkText,
                                  subItems: firstLevelSubItems,
                                }) => (
                                  <SubMenuItem key={subId}>
                                    <MenuLinkItem
                                      title={subTitle}
                                      link={subLink}
                                      currentPage={currentPage}
                                      isActive={subLink?.url === activeItem || activeItem.startsWith(subLink.url)}
                                      onClick={toggleColumn(
                                        firstLevelSubItems,
                                        subLink,
                                        activeItem,
                                        setActiveItem,
                                        link,
                                        currentPage,
                                      )}
                                    />
                                    {/* SECOND COLUMN */}
                                    {firstLevelSubItems?.length > 0 && isFirstColumnOpen && (
                                      <SecondColumn
                                        activeItem={activeItem}
                                        currentPage={currentPage}
                                        subLink={subLink}
                                        setActiveItem={setActiveItem}
                                        linkText={subLinkText}
                                        subItems={firstLevelSubItems}
                                        columnRef={secondColumnRef}
                                        thirdColumnRef={thirdColumnRef}
                                      />
                                    )}
                                  </SubMenuItem>
                                ),
                              )}
                            </MenuList>
                          </MenuGroup>
                        ))}
                      </ColumnWrapper>
                    </ScrollContainer>
                    <LinearBlur />
                  </FirstColumnMenu>
                  <SupportingItemsContainer compact={condenseSupportingItem} isOpen={hideSupportingItem}>
                    <ScrollContainer>
                      <SupportingItemsWrapper>
                        <SupportingItem
                          supportingItem={supportingItem}
                          activeItem={activeItem}
                          currentPage={currentPage}
                        />
                      </SupportingItemsWrapper>
                    </ScrollContainer>
                    <LinearBlur hexColor={theme.colors.blueIce} />
                  </SupportingItemsContainer>
                </ExpandedMenuWrapper>
                <MenuFooter>
                  <MenuFooterContent>{needHelp && <NeedHelp {...needHelp} />}</MenuFooterContent>
                </MenuFooter>
              </ExpandedMenuContainer>
            )}
          </Component>
        )
      })}
    </MainMenuItems>
  )
}

export default SiteNavigationDesktop
