import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import { styled } from 'styled-components'

import { SiteNavigationItem, SiteNavigationNeedHelp, SiteNavigationOverflowItem } from '@/queries/schema.generated'
import useIsAtTop from '@/utils/useIsAtTop'
import useClickOutside from '@/utils/useClickOutside'
import SiteNavigationBar from './SiteNavigationBar'
import SiteNavigationMobile from './SiteNavigationMobile'
import { useOnRouteChangeComplete } from '@/hooks/router/useOnRouteChange'
import { legacyBreakpointsNumerical } from '@damen/ui/lib/cjs/styles'
import { NAVIGATION_BREAKPOINT_LARGE } from './consts'

export const showNavigationBarVar = makeVar(false)
export const blockNavigationBarVar = makeVar(0)
/**
 * Whether to ignore scrolling up, preventing the sticky nav bar from appearing.
 * This is especially useful for automated scrolls.
 */
export const ignoreScrollUpVar = makeVar(false)

const MenuOverlay = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: fixed;
  z-index: 6;
  top: 0;
  width: 100%;
  height: 100dvh;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.6s cubic-bezier(1,0,1,0);

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    ${({ isOpen }) =>
      isOpen &&
      `
      visibility: visible;
      opacity: 1;
      cursor: pointer;
    `}
  }
`

const useShowNavigationBar = (timerDisappearTimeout = 5000) => {
  const lastScrollY = useRef(0)
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>()

  const showNavigationBar = useReactiveVar(showNavigationBarVar)
  const blockNavigationBar = useReactiveVar(blockNavigationBarVar)
  const ignoreScrollUp = useReactiveVar(ignoreScrollUpVar)

  // The nav bar must be shown again each time this component is mounted
  useEffect(() => {
    showNavigationBarVar(true)
  }, [])

  // The nav bar must appear at the top of the page
  // The nav bar must appear when the user scrolls up
  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window
      const delta = scrollY - lastScrollY.current
      if (delta === 0) {
        return
      }
      lastScrollY.current = scrollY
      const direction = delta > 0 ? 'down' : 'up'

      if (ignoreScrollUp) {
        return
      }

      showNavigationBarVar(direction === 'up' || scrollY <= 0)
      clearTimeout(timer.current)
    }

    handleScroll()
    document.addEventListener('scroll', handleScroll, {
      passive: true,
    })

    return () => document.removeEventListener('scroll', handleScroll)
  }, [ignoreScrollUp, timerDisappearTimeout])

  return [showNavigationBar && blockNavigationBar === 0] as const
}

interface Props {
  isOpen?: boolean
  items?: SiteNavigationItem[]
  overflowItems?: SiteNavigationOverflowItem[]
  needHelp?: SiteNavigationNeedHelp
  closeMenuTitle?: string
  currentPage?: string
  variant?: 'light' | 'dark'
}

const SiteNavigation: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const [show] = useShowNavigationBar()
  const navigationBarRef = useRef(null)
  const isAtTop = useIsAtTop()
  const [isOpen, setIsOpen] = useState(false)
  const [device, setDevice] = useState(undefined)
  // This is the url of the active sublevel
  const [activeItem, setActiveItem] = useState('')

  const { overflowItems, currentPage } = props

  useLayoutEffect(() => {
    // Can not use the useDevice hook as the menu has a different desktop breakpoint
    const handleResize = () => {
      const { innerWidth } = window

      if (innerWidth < legacyBreakpointsNumerical.md) {
        setDevice('mobile')
      } else if (innerWidth >= legacyBreakpointsNumerical.md && innerWidth < NAVIGATION_BREAKPOINT_LARGE) {
        setDevice('tablet')
      } else if (innerWidth >= NAVIGATION_BREAKPOINT_LARGE) {
        setDevice('desktop')
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const toggleMobileMenu = useCallback(() => {
    setIsOpen((currentIsOpen) => !currentIsOpen)
  }, [setIsOpen])

  const handleRouteChange = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useClickOutside(navigationBarRef, () => {
    if (device === 'desktop') {
      setIsOpen(false)
    }
  })

  useOnRouteChangeComplete(handleRouteChange)

  useEffect(() => {
    if (isOpen) {
      if (!document.body.classList.contains('ReactModal__Body--open')) {
        document.body.classList.add('ReactModal__Body--open')
      }
    } else {
      document.body.classList.remove('ReactModal__Body--open')
    }

    return () => {
      document.body.classList.remove('ReactModal__Body--open')
    }
  }, [isOpen])

  useEffect(() => {
    // Only on desktop do we want all columns for the current url to be visible as
    // only there we can see all columns at the same time
    if (!isOpen && device === 'desktop') {
      setActiveItem(currentPage === '/' ? '' : currentPage)
    } else if (!isOpen && device !== 'desktop') {
      setActiveItem('')
    }
  }, [isOpen, device])

  const mobileOverflowItems = useMemo(
    () => overflowItems?.filter((item) => item.link.url !== '/search/'),
    [overflowItems],
  )

  return (
    <nav>
      <SiteNavigationBar
        navigationBarRef={navigationBarRef}
        show={show}
        isAtTop={isAtTop}
        isMenuOpen={isOpen}
        setIsMenuOpen={setIsOpen}
        toggleMobileMenu={toggleMobileMenu}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        currentPage={currentPage}
        {...props}
      />
      {/* MOBILE MENU */}
      <SiteNavigationMobile
        {...props}
        isMenuOpen={isOpen}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        overflowItems={mobileOverflowItems}
        currentPage={currentPage}
      />
      <MenuOverlay isOpen={isOpen} title={props.closeMenuTitle} />
    </nav>
  )
}

export default SiteNavigation
